import { env } from '@env/dev.env'

/**
 * GET obtener-todos
 * PATCH archivar/{cuenta_id}/{valor}
 * POST registrar
 * PATCH actualizar/{cedula}
 * POST persona-especialidad/asignar/{persona_id}
 */
export const ApiPersonnelManagement = {
  obtenerTodos: `${env.api_url}persona/obtener-todos`,
  archivar: `${env.api_url}cuenta/archivar`,
  crear: `${env.api_url}cuenta/registrar`,
  actualizar_persona: `${env.api_url}persona/actualizar`,
  asignarEspecialidades: `${env.api_url}persona-especialidad/asignar`,
}
