import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, retry, throwError } from 'rxjs'
import { ToastService } from './toast.service'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private srvToast: ToastService) {}

  connHandler = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred
      this.srvToast.error({
        title: `Error ${error.status}`,
        message: 'Se perdió la conexión',
      })
    }
    return throwError(() => error)
  }

  get<T>(endpoint: string): Observable<any> {
    return this.http
      .get<T>(endpoint)
      .pipe(retry(1), catchError(this.connHandler))
  }
  post<T>(endpoint: string, body: object): Observable<any> {
    return this.http
      .post<T>(endpoint, body)
      .pipe(retry(1), catchError(this.connHandler))
  }
  patch<T>(endpoint: string, body: object): Observable<any> {
    return this.http
      .patch<T>(endpoint, body)
      .pipe(retry(1), catchError(this.connHandler))
  }
  delete<T>(endpoint: string): Observable<any> {
    return this.http
      .delete<T>(endpoint)
      .pipe(retry(1), catchError(this.connHandler))
  }
}
